@import "../../css-config/mixins.scss";

.main-header {
  width: 100%;
  padding: 0 20px;
  background: linear-gradient(180deg, rgba(0, 147, 221, 1) 0%, rgba(31, 49, 135, 1) 100%);
  color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99;
  @include sm {
    // background-color: ;
  }
  @include md {
    padding: 0 40px;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    max-width: 1200px;
    margin: 0 auto;
    @include md {
      height: 80px;
    }
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      gap: 10px;
      @media (min-width: 426px){
        gap: 25px;
      }
      @include md {
        display: flex;
      }
      li {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        @media (min-width: 426px){
          font-size: 14px;
        }
      }
      .navbar-item {
        float: left;
        display: block;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
      }
  
      .navbar-item:hover {
        background-color: #111;
      }
  
      .dropdown {
        position: relative;
        display: inline-block;
      }
  
      .dropdown-menu {
        display: none;
        position: absolute;
        background-color: #8812be;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      }
  
      .dropdown:hover .dropdown-menu {
        display: block;
      }
  
      .nested-dropdown {
        position: relative;
        display: inline-block;
      }
  
      .nested-dropdown-menu {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        left: 100%;
        top: 0;
      }
  
      .nested-dropdown:hover .nested-dropdown-menu {
        display: block;
      }
    }
    .center img{
      display: flex;
      align-items: center;
      width: 100px;
      @include md {
        width: 150px;
      }
    }
    .right {
      align-items: center;
      gap: 20px;
      // margin-right: -80px;
      border-radius: 30px;
      cursor: pointer;

      /* CSS */
      .button-74 {
        display: flex!important;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #fff;
        border: 2px solid #422800;
        border-radius: 30px;
        box-shadow: #422800 4px 4px 0 0;
        color: #1164c3;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        padding: 0 18px;
        line-height: 50px;
        text-align: center;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
      }

      .button-74:hover {
        background-color: wheat;
      }

      .button-74:active {
        box-shadow: #422800 2px 2px 0 0;
        transform: translate(2px, 2px);
      }

      @media (max-width: 767px ) {
        .button-74 {
          min-width: 112px;
          gap: 2px;
          line-height: 30px;
          padding: 0;
          font-size: 12px;
        }
      }
      @media (min-width: 768px) {
        .button-74 {
          min-width: 120px;
          padding: 0 25px;
        }
      }
      @include md {
        gap: 25px;
      }
      svg {
        font-size: 20px;
        cursor: pointer;
        @include md {
          font-size: 24px;
        }
      }
    }
  }
  &.sticky-header {
    position: sticky;
    top: 0;
    transform: translateY(-80px);
    animation: StickyHeader 0.3s ease forwards;
  }
}

@keyframes StickyHeader {
  0% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0);
  }
}
