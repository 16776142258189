@import "../../css-config/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Bentham&family=Gasoek+One&display=swap");
.main-content {
  .layout {
    max-width: calc(100% - 20px);
    margin: 0 auto;
    // @include md {
    //     max-width: 1300px;
    // }
  }
}

/* AboutUsPage.css */
.about-us-page {
  background: url("../../assets/fittings-2784901_960_720.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1;
  margin: 10px 0px;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  background: rgb(4 30 49 / 88%);
}

.about-us-content {
  //   max-width: 800px;
  //   padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 375px) {
    padding: 20px 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 100px;
  }
  @media (min-width: 1024px) {
    padding: 20px 200px;
  }
}

.about-us-content h1 {
  padding: 10px;
  margin-bottom: 10px;
  font-family: "Bentham", serif;
  font-family: "Gasoek One", sans-serif;
  margin: 20px 0px;
  background: linear-gradient(
    180deg,
    rgba(0, 147, 221, 1) 0%,
    rgba(31, 49, 135, 1) 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  font-size: 20px;
  // font-weight: 900;
  text-transform: uppercase;
  @media (min-width: 426px) {
    font-size: 35px;
  }
  @media (min-width: 769px) {
    font-size: 40px;
  }
}

.about-us-content p {
  text-align: justify;
  color: white;

  //   @media(min-width: 428px){
  //     font-size: 1rem;
  //     line-height: 1.2;
  //     margin: 10px 0;
  //   }
  @media (min-width: 375px) {
    font-size: 1rem;
    line-height: 1.2;
    margin: 10px 0;
  }
  @media (min-width: 768px) {
    font-size: 0.95rem;
    line-height: 1.2;
    margin: 10px 0;
  }
  @media (min-width: 1025px) {
    font-size: 1.3rem;
    line-height: 1.4;
    margin: 20px 0;
  }
}
