@import "../../../css-config/mixins.scss";

.slider{
  // margin: 0 auto;
  position: relative;
  overflow: hidden;
  @include md {
    width: 100%;
  }
  img {
    z-index: 5;
    width: 100%;
    height: calc(40vh - 80px);
    background-position: center;
    background-size: cover;
    @include sm {
      width: 100%;
      height: calc(60vh - 80px);
    }
    @include md {
      width: 100%;
      height: calc(80vh - 80px);
    }
    @include lg {
      width: 100%;
      height: calc(100vh - 80px);
    }
  }
}
// .slide::before {
//   position: absolute;
//   display: block;
//   transform: translate(-100%);
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.147);
//   content: '';
// }
