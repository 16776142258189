@import "../../css-config/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Bentham&family=Gasoek+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.products-container {
  margin: 30px 0;
  @include md {
    margin: 50px 0;
  }
  .alert {
    background-color: aqua;
    overflow: hidden;
    height: 20px;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    @media (min-width: 426px) {
      height: 30px;
    }
    p {
      font-size: 10px;
      text-align: center;
      margin: 15px 0px;
      font-weight: bolder;
      color: #8e2de2;
      text-transform: uppercase;
      animation: moveLeftToRight 8s linear infinite;
      @media (min-width: 426px) {
        font-size: 15px;
      }
    }
    @keyframes moveLeftToRight {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  }

  .category {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    .btn {
      font-family: 'Poppins', sans-serif;
      background: linear-gradient(180deg, rgba(0, 147, 221, 1) 0%, rgba(31, 49, 135, 1) 100%);
      height: 45px;
      border: none;
      font-size: 8px;
      border-radius: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      //   width: calc(22% - 50px);
      cursor: pointer;
      overflow: hidden;
      // margin-bottom: 50px;
      position: relative;
      @media (min-width: 426px) {
        font-size: 15px;
      }
    }
    .btn:hover{
      transition: all ease 0.3s;
      font-size: medium;
      transform: scale(1.1);
      opacity: 0.9;
    }
    .sp1 {
      margin-top: 0;
    }
  }
  .sec-heading {
    padding-top: 10px;
    font-family: "Bentham", serif;
    font-family: "Gasoek One", sans-serif;
    margin: 20px 0px;
    background: linear-gradient(180deg, rgba(0, 147, 221, 1) 0%, rgba(31, 49, 135, 1) 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    font-size: 20px;
    // font-weight: 900;
    text-transform: uppercase;
    @media (min-width: 426px) {
      font-size: 35px;
    }
    @media (min-width: 769px) {
      font-size: 40px;
    }

    &:after {
      content: "";
      display: block;
      margin: 5px auto;
      width: 120px;
      height: 3px;
      background-color: aqua;
      @media (min-width: 426px) {
        margin: 20px auto;
        width: 224px;
      }
    }
  }
  .products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 40px auto;
    @include sm {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
    @include lg {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
    }
  }
}
