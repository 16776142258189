@import "../../../css-config/mixins.scss";

.sec-head {

  margin: 60px 35px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  @include sm {
    // margin-bottom: 35px;
    font-size: 24px;
  }
  &:after {
    content: "";
    display: block;
    width: 170px;
    height: 3px;
    margin: 5px auto;
    background-color: #8e2de2;
    @include sm {
      width: 224px;
      margin-top: 10px;
    }
  }
}

.related-products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  margin: 50px 10px;
  gap: 20px;
  // width: 100%!important;
  @include sm {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
  }
//   @include md {
//     grid-template-columns: 1fr 1fr 1fr;
//   }
  @include lg {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .product-card{
    // width: 262px;
    // @media (min-width: 640px) { 
    //     width: 200px;
    // }
    // @media (min-width: 768px) { 
    //     width: 200px;
    // }
    // @media (min-width: 1024px) { 
    //     width: 200px;
    // }
  }
}
