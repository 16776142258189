@import "../../css-config/mixins.scss";

.single-product-main-content {
  margin: 20px 0;
  @include md {
    margin: 75px 0;
  }
  .layout {
    max-width: calc(100% - 20px);
    margin: 0 auto;
    @include md {
      // max-width: 1200px;
    }
    .single-product-page {
      display: flex;
      flex-direction: column;
      @media (min-width: 780px) {
        margin: 0 auto;
        max-width: 1200px;
        flex-direction: row;
      }
      .left {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        flex-shrink: 0;
        @include md {
          width: 600px;
          height: 600px;
        }
        @media (min-width: 760px) {
          margin: 0 auto;
        }
        img {
          width: 100%;
          display: block;
          
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        @include sm {
          padding: 0 22px;
        }
        .text-bold {
          color: #a4a2a2;
          margin-bottom: 10px;
        }
        .name {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 20px;
          @include md {
            font-size: 30px;
            line-height: 32px;
          }
        }
        .price {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 20px;
        }
        .desc {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
          color: #6b6b6b;
          @include md {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }

        .divider {
          margin: 20px 0;
          height: 1px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.1);
        }

        .info-item {
          .text-bold {
            font-size: 18px;
            font-weight: 500;
            display: block;
            &:nth-child(1) {
              margin-bottom: 20px;
            }
            span {
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
              color: #6b6b6b;
            }
            svg {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
