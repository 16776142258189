@import "../../../css-config/mixins.scss";
.product-card {
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    .thumbnail {
        width: 130px;
        height: 130px;
        background-color: rgba(0, 0, 0, 0.05);
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        display: flex;
        align-items: center;
        @media only screen and (min-width: 370px) and (max-width: 479px){
            height: 170px;
            width: 170px;
        }
        @media only screen and (min-width: 480px) and (max-width: 640px){
            height: 230px;
            width: 230px;
        }
        @include sm{
            height: 235px;
            width: 235px;
        }
        // @include md{
        //     height: 240px;
        //     width: 240px;
        // }
        @include xl{
            height: 270px;
            width: 270px;
        }
        img {
            transition: all ease 0.3s;
            display: block;
            width: 100%;
        }
    }
    .prod-details {
        .name {
            text-align: center;
            font-size: 14px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @include md {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }
    &:hover {
        .thumbnail {
            img {
                transform: scale(1.2);
            }
        }
    }
}